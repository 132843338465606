/**
 * Help Banner
 *
 */
.its-help-banner {

    &__header{
        text-align: center;
        background-color: $wcm-dark-orange;
        padding-top: 1.5rem;
        padding-bottom: 1.25rem;

        h2{
            color: $wcm-white;
            margin: 0;
        }
    }

    &__cta{
        .wcm-cta{
            &__tile{
                display: inline-block;
                z-index: initial;
                float: none;
                width: 49%;
                height: auto;
                border: none;
                padding: 2rem 1rem;
                margin: 2.5% .5%;

                &:nth-of-type(1),
                &:nth-of-type(3){
                    border-right: 1px solid $wcm-border-gray;
                }
        
                @include breakpoint($md) {
                    width: 25%;
                    margin: 1rem 0;
                    
                    &:not(:last-child){
                        border-right: 1px solid $wcm-border-gray;
                    }
                    
                }
        
                &:hover {
                    z-index: 100;
                    border: none;
                }
           }

           &__detail{
                position: relative;
                top: unset;
                left: unset;
                transform: unset;
                width: auto;
                font-size: unset;

                .cta-icon{
                    width: 50px;
                    height: auto;
                    max-height: 50px;
                    margin-bottom: 1rem;
                }

           }
        }

    }

    &__footer{
        text-align: center;
        margin-top: 1rem;
        font-size: $font-size-h4;
        border-top: 1px solid $wcm-border-gray;
        padding-top: 1rem;

        span{
            display: block;

            &.footer-phone_number{
                font-weight: bold;
            }

            @include breakpoint($md){
                display: inline-block;

                &.footer-phone_number, &.footer-phone_days{
                    &:after{
                        content: " •";
                    }
                }
            }
        }

        @include breakpoint($md){
            border-bottom: 1px solid $wcm-border-gray;
            padding-bottom: 1rem;
        }
        
    }
    
}

.its-help-banner-modal{

    &__title{
        display: inline-block;
    }

    .close{
        color: $wcm-dark-orange;
        fill: $wcm-bright-orange;
        display: inline-block;
        opacity: 0.7;

        &:hover,
        &:focus {
            color: $wcm-dark-orange;
            opacity: 0.9;
        }

    }

    &__smartdesk{
        margin-bottom: 1.5rem;
    }

    &__location{
        display: inline-block;
        width: 49%;

        p{
            margin-bottom: .15rem;
            &:first-of-type {
            
                font-weight: bold;
            }
        }
    }

    &__map{
        min-height: 58vh;
        border: 1px solid $wcm-black;
    }
}