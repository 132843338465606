// Bring in the vars and custom mixins defined in the WCMC Bootstrap theme. Sets the WCMC Colors and Breakpoints
@import "../../../../../../profiles/wcmc/themes/wcmc_bootstrap/scss/_variables";
@import "../../../../../../profiles/wcmc/themes/wcmc_bootstrap/scss/_mixins";

@import "./newsletter";
@import "./its_help_banner";

#modal-content{
    .panopoly-magic-preview-single .fieldset-wrapper{
        max-height: unset !important;
    }
}

// Style the admin button
.navbar-icon-wcm{
    &::before{
        background-image: url("../images/icon-apps.png");
    }

    &.active::before{
        background-image: url("../images/icon-apps-active.png");
    }
}