// Latest Newsletter pane
.pane-wcm-its-latest-newsletter{
    margin-top: 2rem;
    .its-latest-newsletter-pane{
        background-color: $wcm-bg-gray;
        padding: 10px 20px 0px 20px;
    
        .help{
            .icon{
                width: auto;
                margin-right: 15px;
            }
        }
    }    
}